<template>
    <v-row align="center">
        <v-col :cols="12">
            <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="localDate"
                        :label="dateLabel"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        clearable
                        :disabled="disabled"
                        v-on="on"
                    />
                </template>
                <v-date-picker
                    v-model="localDate"
                    :allowed-dates="allowedDates"
                    @input="setDate"
                />
            </v-menu>
        </v-col>
        <v-col v-if="displayTime" cols="12">
            <v-menu
                v-model="timeMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="localTime"
                        :label="timeLabel"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        clearable
                        :disabled="disabled"
                        v-on="on"
                        @input="setTime"
                    />
                </template>
                <v-time-picker
                    v-model="localTime"
                    format="24hr"
                    @input="setTime"
                />
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        props: {
            dateLabel: {
                type: String,
                default: null
            },
            timeLabel: {
                type: String,
                default: null
            },
            date: {
                type: String,
                default: null
            },
            dateRange: {
                type: Array,
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            },
            time: {
                type: String,
                default: null
            },
            type: {
                type: String,
                default: ''
            },
            displayTime: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            localDate: null,
            localTime: null,
            dateMenu: false,
            timeMenu: false
        }),
        computed: {
            allowedDates() {
                if (this.type === 'Start') {
                    return val => new Date(val).getDay() === 1
                } else if (this.type === 'End') {
                    return val => new Date(val).getDay() === 0
                } else if (this.dateRange) {
                    return val => new Date(val) >= new Date(this.dateRange[0]) && new Date(val) <= new Date(this.dateRange[1])
                } else {
                    return null
                }
            }
        },
        created() {
            this.localDate = this.date
            this.localTime = this.time
        },
        watch: {
            date() {
                this.localDate = this.date
            },
            time() {
                this.localTime = this.time
            }
        },
        methods: {
            setDate(date) {
                this.$emit('setDate', date)
            },
            setTime(time) {
                this.$emit('setTime', time)
            }
        }
    }
</script>
