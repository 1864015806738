import api from '@/utils/api'

export default {
    getPlayers(page, size) {
        let range = 'range=' + encodeURIComponent(`[${page - 1},${size}]`)
        let query = 'filter=' + encodeURIComponent('{"active": true }')
        return api.get(`players?` + (page ? range : '') + query)
    },
    getFilteredPlayers(filters, page, size) {
        let query = (filters ? filters + '&range=' : '?range=') + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get(`players` + query)
    },
    getPlayer(id) {
        return api.get(`players/${id}`)
    },

    createPlayer(data) {
        return api.post('players', data)
    },

    updatePlayer(id, data) {
        return api.put(`players/${id}`, data)
    },

    uploadFile(formData) {
        return api.uploadFile('uploads/player', formData)
    },

    deletePlayer(id) {
        return api.delete(`players/${id}`)
    }
}
