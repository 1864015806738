<template>
    <v-container>
        <v-card class="mb-10">
            <v-card-title class="card-title">Player Form</v-card-title>
        </v-card>
        <v-form :readonly="readOnly">
            <v-row>
                <v-col cols="6" lg="3">
                    <v-switch
                        v-model="player.active"
                        hide-details
                        inset
                        :label="player.active ? 'Active' : 'Not Active'"
                    />
                </v-col>
                <v-col cols="6" lg="3">
                    <v-switch
                        v-model="player.leader"
                        hide-details
                        inset
                        :label="player.leader ? 'Leader' : 'Regular'"
                    />
                </v-col>
                <v-col cols="6" lg="3">
                    <v-switch
                        v-model="player.doctorCheck"
                        hide-details
                        inset
                        :label="`Doctor Check: ${player.doctorCheck ? 'Ok' : 'Pending'}`"
                    />
                </v-col>
                <v-col cols="6" lg="3">
                    <v-select
                        v-model="player.noOfMatches"
                        :items="['noOfMatches1', 'noOfMatches2', 'noOfMatches3']"
                        label="MatchesRule"
                        :clearable="!readOnly"
                    >
                        <template slot="selection" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                            {{ `#MatchesRule${data.item.slice(-1)}` }}
                        </template>
                        <template slot="item" slot-scope="data">
                            <!-- HTML that describe how select should render items when the select is open -->
                            {{ `#MatchesRule${data.item.slice(-1)}` }}
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="6" lg="3">
                    <v-text-field
                        v-model="player.name"
                        label="Name"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="6" lg="3">
                    <v-text-field
                        v-model="player.surname"
                        label="Surname"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="6" lg="3">
                    <v-autocomplete
                        v-model="player.team"
                        :items="teams"
                        item-text="name"
                        menu-props="auto"
                        label="Team"
                        hide-details
                        :clearable="!readOnly"
                        return-object
                    />
                </v-col>
                <v-col cols="6" lg="3">
                    <date-time-picker
                        :date-label="'Birthday'"
                        :date="birthday"
                        @setDate="setBirthday"
                    />
                </v-col>
                <v-col cols="6" lg="3">
                    <v-text-field
                        v-model="player.residence"
                        label="Residense"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="6" lg="3">
                    <v-text-field
                        v-model="player.mobile"
                        label="Mobile"
                        maxlength="10"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="6" lg="3">
                    <v-text-field
                        v-model="player.height"
                        label="Height"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="6" lg="3">
                    <v-text-field
                        v-model="player.jersey"
                        :disabled="!player.active"
                        label="Jersey"
                        type="number"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="6" lg="3">
                    <v-text-field
                        v-model="player.athleteNumber"
                        label="Athlete Number"
                        type="number"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="6" lg="3">
                    <v-select
                        v-model="player.position"
                        :items="positions"
                        menu-props="auto"
                        label="Position"
                        hide-details
                        :clearable="!readOnly"
                    />
                </v-col>
                <v-col cols="6">
                    <v-file-input
                        v-model="image"
                        class="mt-3"
                        accept="image/*"
                        show-size
                        label="Image"
                        :clearable="clearable"
                        @change="changeImage"
                        @click:clear="clearImage()"
                    />
                </v-col>
                <v-col cols="6">
                    <v-img
                        v-if="image"
                        width="200px"
                        height="auto"
                        contain
                        :src="imagePath"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-card width="100%" class="mb-3">
                    <v-card-title>Highlights</v-card-title>
                    <div class="highlight-input">
                        <v-text-field
                            v-model="highlightUrl"
                            label="Add Video Highlight URL"
                            type="text"
                            :clearable="clearable"
                        />
                        <v-btn
                            :disabled="!highlightUrl || !validURL(highlightUrl)"
                            class="mx-2 white--text"
                            color="black"
                            @click="addHighlight"
                        >
                            Add
                        </v-btn>
                    </div>
                    <v-chip-group class="hightlight-urls" column>
                        <div v-for="(highlight, index) of this.player.highlights" :key="index" class="mx-3">
                            <v-chip @click="openLink(highlight)">Highlight {{ index + 1 }}</v-chip>
                            <delete-button :type="false" @delete-entry="onHighlightDelete" :color="'red'" />
                        </div>
                    </v-chip-group>
                </v-card>
            </v-row>
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn :loading="loading" class="mx-2 white--text" :disabled="shouldDisableSubmit()" large color="black" @click="onSubmit">
                Submit
            </v-btn>
            <v-btn class="mx-2 white--text" large color="red" @click="$router.go(-1)">
                Cancel
            </v-btn>
        </div>
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import playerService from '@/services/player'
    import Alert from '@/components/Alert'
    import DateTimePicker from '@/components/DateTimePicker'
    import momentTimezone from 'moment-timezone'
    import teamService from '@/services/team'
    import DeleteButton from '@/components/DeleteButton'

    export default {
        name: 'PlayerForm',
        components: { Alert, DateTimePicker, DeleteButton },
        props: {
            propId: {
                type: Number,
                default: null
            },
            playerApplication: {
                type: Object,
                default: null
            }
        },
        data: () => {
            return {
                highlightUrl: '',
                showAlert: false,
                message: null,
                type: null,
                mode: null,
                readOnly: false,
                clearable: true,
                loading: false,
                birthday: null,
                googleApiHost: 'https://storage.googleapis.com',
                player: {
                },
                playerId: null,
                rules: [
                    v => !!v || 'Field is required'
                ],
                mobileRule: [ v => { if (v?.length < 10 && v?.length > 0) return 'Insert valid mobile number' } ],
                positions: ['guard', 'forward', 'center'],
                image: {
                    name: 'Image file'
                },
                imageChanged: false,
                imagePath: '',
                teams: []
            }
        },
        watch: {
            'player.height': {
                handler(value) {
                    this.player.height = value.replace(',', '.')
                }
            },
            'player.active': {
                handler(value) {
                    if (!value) {
                        this.player.jersey = null
                    }
                }
            },
            propId: {
                handler(value) {
                    this.playerId = this.propId
                    if (this.playerId) {
                        playerService.getPlayer(this.playerId).then(resp => {
                            this.player = resp.data
                            if (this.player.birthday) {
                                this.birthday = momentTimezone(this.player.birthday, 'DD/MM/YYYY').tz('Europe/Athens').format('YYYY-MM-DD').toString()
                                // this.birthday = this.player.birthday
                            }
                            if (this.player.image) {
                                this.imagePath = this.googleApiHost + '/' + this.player.image
                            }
                        })
                    }
                }
            }
        },
        async created() {
            this.mode = this.$route.params.mode
            this.fetchAllTeams()
            if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
            }
            this.playerId = this.propId ? this.propId : this.$route.params.playerId
            if (this.playerId) {
                playerService.getPlayer(this.playerId).then(resp => {
                    this.player = resp.data
                    if (this.player.birthday) {
                        this.birthday = momentTimezone(this.player.birthday, 'DD/MM/YYYY').tz('Europe/Athens').format('YYYY-MM-DD').toString()
                        // this.birthday = this.player.birthday
                    }
                    if (this.player.image) {
                        this.imagePath = this.googleApiHost + '/' + this.player.image
                    }
                })
            } else {
                if (this.playerApplication) {
                    this.player = this.playerApplication
                    this.birthday = this.playerApplication.birthday
                }
            }
        },
        methods: {
            onHighlightDelete(index) {
                this.player.highlights.splice(index, 1)
            },
            addHighlight() {
                if (!this.player.highlights) {
                    this.player.highlights = []
                }
                if (this.highlightUrl && this.validURL(this.highlightUrl)) {
                    this.player.highlights.push(this.highlightUrl)
                    this.highlightUrl = ''
                }
            },
            validURL(str) {
                const a = document.createElement('a')
                a.href = str
                return (a.host && a.host !== window.location.host)
            },
            openLink(url) {
                window.open(url, '_blank')
            },
            clearImage() {
                this.imagePath = ''
                this.player.image = null
            },
            changeImage() {
                this.imageChanged = true
                if (this.image) {
                    this.imagePath = URL.createObjectURL(this.image)
                }
            },
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            shouldDisableSubmit() {
                return false
            },
            setBirthday(date) {
                this.birthday = momentTimezone(date).tz('Europe/Athens').format('YYYY-MM-DD').toString()
            },
            onEdit() {
                this.$router.push(`/players/${this.playerId}/edit`)
            },
            createPlayer() {
                console.log('here')
                this.loading = true
                playerService.createPlayer(this.player).then(playerResponse => {
                    if (playerResponse.id) {
                        this.playerId = playerResponse.id
                    }
                    this.loading = false
                    if (this.$route.name !== 'Team Application Form') {
                        this.$router.push(`/players`)
                    } else {
                        this.$emit('removePlayerForm')
                    }
                }).catch((error) => {
                    this.loading = false
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            editPlayer() {
                this.loading = true
                playerService.updatePlayer(this.playerId, this.player).then(playerResponse => {
                    if (playerResponse.id) {
                        this.playerId = playerResponse.id
                    }
                    this.loading = false
                    if (this.propId) {
                        this.$emit('updatePlayer')
                    } else {
                        this.$router.push(`/players`)
                    }
                }).catch((error) => {
                    this.loading = false
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            async onSubmit() {
                const uploadImageResponse = await this.uploadImage()
                const imageUrl = uploadImageResponse?.data.content || null
                if (!this.player.highlights?.length) {
                    delete this.player.highlights
                }
                if (imageUrl) {
                    this.player.image = imageUrl
                }
                if (this.birthday) {
                    this.player.birthday = momentTimezone(this.birthday).tz('Europe/Athens').format('DD/MM/YYYY').toString()
                }
                if (this.mode === 'edit') {
                    this.editPlayer()
                } else if (this.mode === 'create') {
                    this.createPlayer()
                } else {
                    this.createPlayer()
                }
            },
            uploadImage() {
                if (!this.imageChanged || this.image == null) {
                    return
                }
                let formData = new FormData()
                formData.append('file', this.image)
                return playerService.uploadFile(formData)
            },
            fetchAllTeams() {
                teamService.getTeams().then(resp => {
                    this.teams = resp.data.content
                })
            }
        }

    }
</script>
<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}

</style>
<style scoped>
.v-chip {
    margin: 0 !important;
}

.choices {
    display: flex;
    justify-content: space-evenly;
}

.highlight-input {
    width: 90%;
    margin-left: 1rem;
    display: flex;
    align-items: baseline;
}

.hightlight-urls {
    padding: 1rem;
}

.body-label {
    color: rgba(0, 0, 0, 0.6);
}
.body-editor {
    margin-bottom: 2vh;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.img-preview {
    max-width: 70%;
    margin: 0 auto;
}

.image-input {
    padding: 20px;
    width: 100%;
    border-style: groove;
    border: #D3D3D3 solid 2px;
    border-radius: 15px 50px;
    margin-top: 1vh;
}

.green-border {
    border-color: green;
}

.delete-btn {
    display: flex;
    justify-content: flex-end;
}
</style>
