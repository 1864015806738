import api from '@/utils/api'

export default {
    getTeams(page, size) {
        let range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get(`teams` + (page ? range : ''))
    },

    getFilteredTeams(filters, page, size) {
        let query = (filters ? filters + '&range=' : '?range=') + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get(`teams` + query)
    },
    getTeam(id) {
        return api.get(`teams/${id}`)
    },

    updateTeam(id, data) {
        return api.put(`teams/${id}`, data)
    },

    createTeam(data) {
        return api.post('teams', data)
    },

    uploadFile(formData) {
        return api.uploadFile('uploads/team', formData)
    },

    deleteTeam(id) {
        return api.delete(`teams/${id}`)
    }
}
